.display {
    font-family: $display !important;
}

.light {
    font-family: $cuerpo !important;
    font-weight: normal !important;
    letter-spacing: 0.08em !important;
    line-height: 0.8;
}

.pleca {
    border-top: solid 0px;
    border-right: solid 0px;
    border-left: solid 0px;
    border-bottom: solid 20px;
    border-image: linear-gradient(90deg, $azul-bajo, $azul-fuerte) 1;
    padding-bottom: 30px;
    display: inline-block;
}

.pleca-sm {
    border-bottom-width: 10px !important;
    padding-bottom: 20px !important;
}

.btn {
    box-shadow: 0 0 10px black;
}

body {
    line-height: 26.4px;
    overflow-x: hidden;

    p {
        margin-bottom: 30px;
    }
}

.spacing {
    letter-spacing: 5px;
}

/* SECCIONES */

.foto {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: inset 5px 0 5px 0px rgba(black, 0.5);
    min-height: 350px;
    transition: 1s ease-in-out;
}

.our-process {
    color: $azul-fuerte;

    .trust {
        font-size: 3rem;
        font-weight: normal;
    }

    .petroleum {
        font-size: 1.4rem;
        padding: 0 0.45rem;
        font-weight: normal;
    }

    .foto {
        background-image: url(../img/our-process.jpg);
    }
}

.our-clients {

    @media (max-width: 576px) {
        .centered {
            font-size: 2.4rem !important;
            margin-top: -1.3rem !important;
        }
    }

    position: relative;

    .centered {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        font-size: 3.6rem;
        margin-top: -1.8rem;
        text-shadow: 0 0 3px black;
    }

    .foto {
        color: white;
        text-shadow: 0 0 3px black;
        font-weight: lighter;
        box-shadow: inset 0 3px 5px rgba(black, 0.5);
        position: relative;
        height: 500px;
        
        @media (max-width: 576px) {
            height: 300px;
            .down {
                position: absolute;
                bottom: 2em;
                left: 0.5em;
                right: 0.5em;
            }
        }

        &::after {
            content: ' ';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            mix-blend-mode: color;
            transition: background-color 1s ease-in-out;
        }

        &:hover::after {
            background-color: transparent;
        }

        &:hover {
            background-position: right center;
        }
    }

    .foto-01 {
        background-image: url(../img/our-clients-01.jpg);

        &::after {
            background-color: #71c3ec;
        }
    }

    .foto-02 {
        background-image: url(../img/our-clients-02.jpg);

        &::after {
            background-color: #5692c1;
        }
    }

    .foto-03 {
        background-image: url(../img/our-clients-03.jpg);

        &::after {
            background-color: #356094;
        }
    }

    .foto-04 {
        background-image: url(../img/our-clients-04.jpg);

        &::after {
            background-color: #03286d;
        }
    }
}

.our-products {
    background: #00008f;
    background: linear-gradient(90deg, rgba(#4bc0ff,1) 0%, rgba(#00008f,1) 100%);

    ul{
        li {
            margin-bottom: 0.5em;
        }
    }
}

.contact-us {
    .foto {
        box-shadow: inset -5px 0 5px 0px rgba(black, 0.5);
        background-image: url(../img/contact-us.jpg);
    }
}