.navbar {
    .navbar-brand {
        img {
            max-width: 250px;
        }

        @media (min-width: 992px) {
            img {
                max-width: 380px;
            }
        }
    }

    .navbar-toggler.hamburger[aria-expanded=true] {
        @extend .is-active;
    }

    ul {
        li {
            a {
                color: theme-color('primary') !important;
                padding-left: 1.5rem !important;
                font-family: $headings;
                font-size: 1.5rem;

                &:hover {
                    color: theme-color('dark') !important;
                }
            }

            &.active {
                a {
                    color: theme-color('secondary') !important;

                }
            }
        }
    }
}