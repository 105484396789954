/* TIPOGRAFÍA */

$display: 'zingsansrustldemo-base', sans-serif;
$cuerpo: 'Arquitecta W00 Regular', sans-serif;
$headings: 'Arquitecta W00 Bold', sans-serif;

$font-size-base: 1.45rem;

$font-family-sans-serif: $cuerpo;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $headings !default;

/* COLORES */

$azul-fuerte: #1a2c6b;
$azul-bajo: #8ec3eb;

$blanco: #FFFFFF;
$negro: #000000;

$theme-colors: (
  "primary": $azul-fuerte,
  "secondary": $azul-bajo,
  "white": $blanco
);

/* LINKS */

$link-color:                              $azul-fuerte !default;
$link-decoration:                         none !default;
$link-hover-color:                        lighten($link-color, 15%) !default;
$link-hover-decoration:                   underline !default;
