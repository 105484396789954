/* Bootstrap */
@import '../fontfaces/fonts.css';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Raleway:400,700&display=swap');
@import './variables';
@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss';

/* PARTES DEL SITIO */
@import './comun';

@import './header';
@import './portada';
@import './footer';
